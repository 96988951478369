import React, { useState, useEffect, useCallback } from "react";
import ReactGlobe from "react-globe";
import { Collapse, Row, Col, Container } from "reactstrap";

import defaultMarkers from "./markers";
import markerRenderer from "./markerRenderer";
import "./App.css";

const randomMarkers = defaultMarkers.map((marker) => ({
  ...marker,
  value: 10,
}));

function getTooltipContent(marker) {
  return `
  <div style="display:flex">
    <div>
    <img src="${marker.image}" style="width: 100px; heigth: 40px; border-radius: 10px" />
    </div>
    <div style="margin: 10px; text-align: left;">
      <b style="color: #5e5e61">${marker.city}, ${marker.region}</b><br/>
      <b style="color: #30a89c">EXPLORE</b>
    </div>
  </div>`;
}

function Map() {
  const [markers, setMarkers] = useState([]);
  const [event, setEvent] = useState({});
  const [activeTab, setActiveTab] = useState("");
  const [focus, setFocus] = useState(undefined);

  window.focus = focus;
  window.setFocus = setFocus;

  const toggle = useCallback(
    (tab) => {
      if (activeTab !== tab) setActiveTab(tab);
      else setActiveTab("");
    },
    [activeTab]
  );

  useEffect(() => {
    setMarkers(randomMarkers);
  }, []);

  function onClickMarker(marker, markerObject, event) {
    setFocus  (undefined);
    setEvent({
      type: "CLICK",
      marker,
      markerObjectID: markerObject.uuid,
      pointerEventPosition: { x: event.clientX, y: event.clientY },
    });
    if (marker.category === "America") {
      setActiveTab("America");
    } else if (marker.category === "Europe") {
      setActiveTab("Europe");
    } else {
      setActiveTab("");
    }
  }
  function onDefocus(previousCoordinates, event) {
    setEvent({
      type: "DEFOCUS",
      previousCoordinates,
      pointerEventPosition: { x: event.clientX, y: event.clientY },
    });
    setActiveTab("");
  }

  return (
    <div style={{ position: "relative" }}>
      <div style={{ position: "relative", left: -275, height: 620 }}>
        <ReactGlobe
          focus={focus}
          markers={markers}
          markerOptions={{
            getTooltipContent,
            renderer: markerRenderer,
          }}
          onClickMarker={onClickMarker}
          onDefocus={onDefocus}
          globeOptions={{
            glowCoefficient: 0.2,
            glowColor: "#049cca",
            glowPower: 6,
            glowRadiusScale: 0.4,
            enableGlow: true,
            enableBackground: false,
          }}
          cameraOptions={{
            autoRotateSpeed: -1,
            enableAutoRotate: true,
            enableRotate: true,
            rotateSpeed: 1,
          }}
        />
      </div>

      {event.type && event.type === "CLICK" && (
        <div
          onClick={() => {
            setEvent({
              type: "DEFOCUS",
            });
            setFocus(null);
            setActiveTab("");
          }}
          style={{
            position: "absolute",
            top: 25,
            bottom: 25,
            backgroundColor: "rgba(0,0,0,0.5)",
            color: "#fff",
            fontWeight: 700,
            padding: "5px 10px",
            cursor: "pointer",
            height: 34,
          }}
        >
          <div>{"<-"} ZOOM OUT</div>
        </div>
      )}

      <Tabs activeTab={activeTab} toggle={toggle} />
    </div>
  );
}

const Tabs = (props) => {
  return (
    <div style={{ position: "absolute", width: 500, top: 150, right: 0 }}>
      <div style={{ marginBottom: 20 }}>
        <div
          style={{
            backgroundColor: "#02161f",
            border: "1px solid #fff",
            borderRadius: 5,
            display: "flex",
            borderBottomLeftRadius:
              props.activeTab === "America" ? "initial" : "5px",
            borderBottomRightRadius:
              props.activeTab === "America" ? "initial" : "5px",
          }}
        >
          <b
            style={{
              color: "#009dca",
              padding: "10px 20px",
              display: "inline-block",
            }}
          >
            NORTH AMERICAN DATA CENTERS
          </b>
          <div
            color="primary"
            onClick={() => props.toggle("America")}
            style={{
              marginLeft: "auto",
              height: 44,
              fontSize: 32,
              lineHeight: "44px",
              fontFamily: "sans-serif",
              cursor: "pointer",
              padding: props.activeTab === "America" ? "0 19px" : "0 15px",
              borderTopRightRadius: "5px",
              borderBottomRightRadius:
                props.activeTab === "America" ? "initial" : "5px",
              backgroundColor:
                props.activeTab === "America" ? "#fff" : "#f89c1b",
              color: props.activeTab === "America" ? "#28a498" : "#fff",
            }}
          >
            {props.activeTab === "America" ? "-" : "+"}
          </div>
        </div>
        <Collapse
          isOpen={props.activeTab === "America"}
          style={{ backgroundColor: "#fff" }}
        >
          <Container>
            <Row>
              <Col sm="12">
                <div style={{ display: "flex", flexWrap: "wrap", padding: 20 }}>
                  {defaultMarkers
                    .filter((x) => x.category === "America")
                    .map((item, i) => (
                      <div
                        key={i}
                        style={{
                          width: 150,
                          flex: "1 0 34%",
                          marginBottom: 20,
                        }}
                      >
                        <h4
                          style={{
                            color: "#28a498",
                            fontWeight: "bold",
                            fontSize: 18,
                            marginBottom: 5,
                          }}
                        >
                          {item.city}, {item.region}
                        </h4>
                        <div>
                          <b style={{ color: "#585858", fontSize: 14 }}>
                            XXXX Street
                          </b>
                          <br />
                          <b style={{ color: "#585858", fontSize: 14 }}>
                            City, State XXXXX
                          </b>
                        </div>
                      </div>
                    ))}
                </div>
              </Col>
            </Row>
          </Container>
        </Collapse>
      </div>

      <div style={{ marginBottom: 20 }}>
        <div
          style={{
            backgroundColor: "#02161f",
            border: "1px solid #fff",
            borderRadius: 5,
            display: "flex",
            borderBottomLeftRadius:
              props.activeTab === "Europe" ? "initial" : "5px",
            borderBottomRightRadius:
              props.activeTab === "Europe" ? "initial" : "5px",
          }}
        >
          <b
            style={{
              color: "#009dca",
              padding: "10px 20px",
              display: "inline-block",
            }}
          >
            EUROPEAN DATA CENTERS
          </b>
          <div
            color="primary"
            onClick={() => props.toggle("Europe")}
            style={{
              marginLeft: "auto",
              height: 44,
              fontSize: 32,
              lineHeight: "44px",
              fontFamily: "sans-serif",
              cursor: "pointer",
              padding: props.activeTab === "Europe" ? "0 19px" : "0 15px",
              borderTopRightRadius: "5px",
              borderBottomRightRadius:
                props.activeTab === "Europe" ? "initial" : "5px",
              backgroundColor:
                props.activeTab === "Europe" ? "#fff" : "#f89c1b",
              color: props.activeTab === "Europe" ? "#28a498" : "#fff",
            }}
          >
            {props.activeTab === "Europe" ? "-" : "+"}
          </div>
        </div>
        <Collapse
          isOpen={props.activeTab === "Europe"}
          style={{ backgroundColor: "#fff" }}
        >
          <Container>
            <Row>
              <Col sm="12">
                <div style={{ display: "flex", flexWrap: "wrap", padding: 20 }}>
                  {defaultMarkers
                    .filter((x) => x.category === "Europe")
                    .map((item, i) => (
                      <div
                        key={i}
                        style={{
                          width: 150,
                          flex: "1 0 34%",
                          marginBottom: 20,
                        }}
                      >
                        <h4
                          style={{
                            color: "#28a498",
                            fontWeight: "bold",
                            fontSize: 18,
                            marginBottom: 5,
                          }}
                        >
                          {item.city}, {item.region}
                        </h4>
                        <div>
                          <b style={{ color: "#585858", fontSize: 14 }}>
                            XXXX Street
                          </b>
                          <br />
                          <b style={{ color: "#585858", fontSize: 14 }}>
                            City, State XXXXX
                          </b>
                        </div>
                      </div>
                    ))}
                </div>
              </Col>
            </Row>
          </Container>
        </Collapse>
      </div>
    </div>
  );
};

function App() {
  return (
    <div style={{ backgroundColor: "black" }}>
      <Container>
        <Row>
          <Col sm={12}>
            <Map />
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default App;

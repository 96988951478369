export default [
  {
    id: 1,
    city: "Santa Clara",
    region: "CA",
    coordinates: [37.3708853, -122.002572],
    category: "America",
    image: "/data-center.jpg",
  },
  {
    id: 2,
    city: "Seatle",
    region: "WA",
    coordinates: [47.6129432, -122.4821473],
    category: "America",
    image: "/data-center.jpg",
  },
  {
    id: 3,
    city: "Phoenix",
    region: "AZ",
    coordinates: [33.6050991, -112.4052381],
    category: "America",
    image: "/data-center.jpg",
  },
  {
    id: 4,
    city: "Quincy",
    region: "WA",
    coordinates: [47.5682972, -121.709032],
    category: "America",
    image: "/data-center.jpg",
  },
  {
    id: 5,
    city: "Sacramento",
    region: "CA",
    coordinates: [38.5616505, -121.5829965],
    category: "America",
    image: "/data-center.jpg",
  },
  {
    id: 6,
    city: "Las Vegas",
    region: "NV",
    coordinates: [36.1249185, -115.3150843],
    category: "America",
    image: "/data-center.jpg",
  },
  {
    id: 7,
    city: "Dublin",
    region: "Ireland",
    coordinates: [53.3242381, -6.385786],
    category: "Europe",
    image: "/data-center.jpg",
  },
  {
    id: 8,
    city: "Paris",
    region: "France",
    coordinates: [48.8588377, 2.2770203],
    category: "Europe",
    image: "/data-center.jpg",
  },
  {
    id: 9,
    city: "London",
    region: "UK",
    coordinates: [51.5285582, -0.2416809],
    category: "Europe",
    image: "/data-center.jpg",
  },
  {
    id: 10,
    city: "Franckfurt",
    region: "Germany",
    coordinates: [50.1211277, 8.4964814],
    category: "Europe",
    image: "/data-center.jpg",
  },
];

import * as THREE from "three";

const MARKER_COLOR = "#20a7ce";

export default function markerRenderer(marker) {
  const geometry = new THREE.SphereGeometry(5, 10, 10);

  const material = new THREE.MeshBasicMaterial({
    color: new THREE.Color(MARKER_COLOR),
  });

  const mesh = new THREE.Mesh(geometry, material);
  mesh.children = [];

  return mesh;
}
